import { USER_ROLE } from "shared/Constants/general.const";

export const ALL_USER_ROLES = {
  MOXSEA_ADMINISTRATOR: "MOXSEA_Administrator",
  CLUB_WORKER: "CLUB_Worker",
  MOXSEA_MANAGER: "MOXSEA_Manager",
  MOXSEA_SUPPORTER: "MOXSEA_Support",
};

export const ALL_PERMISSION_NAMES = {
  USER_MANAGEMENT: "User Management",
  CLUB_MANAGEMENT: "Club Management",
};

export const getPermissions = (permissionName, userPermissions) => {
  let dashboardAreaPermission = {};

  if (userPermissions) {
    let permissions = userPermissions?.permissions;

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permissionName === permissionName) {
        dashboardAreaPermission = permissions[i];
      }
    }
  }
  return dashboardAreaPermission;
};

export const getReadAccessPermissions = (permissionName, userPermissions) => {
  let readAccessPermission = false;

  if (userPermissions) {
    let permissions = userPermissions?.permissions;

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permissionName === permissionName) {
        readAccessPermission = permissions[i].read;
      }
    }
  }

  return readAccessPermission;
};

export const getReadAccessPermissionsClubs = (
  permissionName,
  userPermissions
) => {
  let readAccessPermissionClubs = false;
  if (userPermissions) {
    let permissions = userPermissions?.permissions;

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permissionName === permissionName) {
        readAccessPermissionClubs = permissions[i].read;
      }
    }
  }

  return readAccessPermissionClubs;
};

export const getReadAccessPermissionsUsers = (
  permissionName,
  userPermissions
) => {
  let readAccessPermissionUsers = false;
  if (userPermissions) {
    let permissions = userPermissions?.permissions;

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permissionName === permissionName) {
        readAccessPermissionUsers = permissions[i].read;
      }
    }
  }

  return readAccessPermissionUsers;
};

export const getWriteClubPermissions = (permissionName, userPermissions) => {
  let readAccessPermissionClubs = false;
  if (userPermissions) {
    let permissions = userPermissions?.permissions;

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permissionName === permissionName) {
        readAccessPermissionClubs = permissions[i].create;
      }
    }
  }

  return readAccessPermissionClubs;
};

export const checkIsUserRoleSupporter = (userPermissions) => {
  if (userPermissions) {
    let permissions = userPermissions?.permissions;

    return permissions.roleName === USER_ROLE.MOXSEA_Support.value;
  }
  return false;
};
