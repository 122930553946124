import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const ForgotPassword = lazy(() => import("./ForgotPassword"));

const ForgotPasswordRoutes = [
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    element: (
      <Suspense>
        <ForgotPassword />
      </Suspense>
    ),
  },
];

export default ForgotPasswordRoutes;
