import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const Notification = lazy(() => import("./Notification"));

const WhatsNewRoutes = [
  {
    path: ROUTE_PATHS.NOTIFICATION,
    element: (
      <Suspense>
        <Notification />
      </Suspense>
    ),
  },
];

export default WhatsNewRoutes;
