import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const Finance = lazy(() => import("./Finance"));

const FinanceRoutes = [
  {
    path: ROUTE_PATHS.FINANCE,
    element: (
      <Suspense>
        <Finance />
      </Suspense>
    ),
  },
];

export default FinanceRoutes;
