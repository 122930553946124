import { Suspense, lazy } from "react";

// const BoatFleetList = lazy(() => import("./BoatFleetList"));

const BoatFleetsList = lazy(
  () => import("../../../BoatFleetsTab/BoatFleetsList")
);

const BoatFleetListRoutes = [
  {
    path: "",
    element: (
      <Suspense>
        <BoatFleetsList />
      </Suspense>
    ),
  },
];

export default BoatFleetListRoutes;
