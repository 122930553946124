import ROUTE_PATHS from "shared/Constants/routes.const";

const { lazy, Suspense } = require("react");

const Login = lazy(() => import("./login"));

const loginRoutes = [
  {
    path: ROUTE_PATHS.LOGIN,
    element: (
      <Suspense>
        <Login />
      </Suspense>
    ),
  },
];

export default loginRoutes;
