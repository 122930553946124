import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";
import ClientsViewAllRoutes from "./Components/ClientsViewAll/ClientsViewAll.routes";
import ClientDetailsRoutes from "./Components/ClientDetails/ClientDetails.routes";
import AddClientRoutes from "./Components/AddClients/AddClients.routes";

const CRM = lazy(() => import("./CRM"));

const CRMRoutes = [
  {
    path: `${ROUTE_PATHS.CRM}/client`,
    children: [...ClientDetailsRoutes],
  },
  {
    path: `${ROUTE_PATHS.CRM}/clients`,
    children: [...ClientsViewAllRoutes],
  },
  {
    path: `${ROUTE_PATHS.CRM}/new-client`,
    children: [...AddClientRoutes],
  },
  {
    path: ROUTE_PATHS.CRM,
    element: (
      <Suspense>
        <CRM />
      </Suspense>
    ),
  },
];

export default CRMRoutes;
