import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BoatFleetList } from "./BoatFleetTab.model";
import { getBoatListDetailed } from "shared/utils/API/boats.api";
import { BoatResponseDetailed } from "shared/model/API/api.boat.model";

const BoatFleetContext = createContext<{
  boatFleet: BoatFleetList;
  onSearch: (value: string) => void;
  getBoatFleetList: () => void;
}>({
  boatFleet: [],
  onSearch: (_value: string) => {},
  getBoatFleetList: () => {},
});

const useBoatFleet = () => {
  const [boatFleet, setBoatFleet] = useState<BoatResponseDetailed[] | null>(
    null
  );

  const [displayBoatFleet, setDisplayBoatFleet] =
    useState<BoatFleetList>(boatFleet);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filterBoatFleet = useCallback(() => {
    /**
     * If Search string is empty do not spend computation on filtering the array with an empty string.
     */
    if (!searchTerm) return boatFleet;
    const filteredBoatFleet = boatFleet?.filter((boat) =>
      `${boat.boatName} ${boat.boatModel}`
        ?.toLowerCase()
        .replaceAll(" ", "")
        .includes(searchTerm?.replaceAll(" ", "").toLowerCase())
    );
    return filteredBoatFleet?.length ? filteredBoatFleet : "NO_RESULTS_FOUND";
  }, [boatFleet, searchTerm]);

  useEffect(() => {
    const filteredBoatFleet: BoatFleetList = filterBoatFleet();
    setDisplayBoatFleet(filteredBoatFleet);
  }, [filterBoatFleet]);

  const getBoatFleetList = useCallback(() => {
    getBoatListDetailed().then((boats) => {
      setBoatFleet([...boats]);
    });
  }, []);

  useEffect(() => {
    getBoatFleetList();
  }, [getBoatFleetList]);

  return { boatFleet: displayBoatFleet, onSearch, getBoatFleetList };
};

export const BoatFleetProvider = ({ children }: { children: any }) => {
  const { boatFleet, onSearch, getBoatFleetList } = useBoatFleet();
  return (
    <BoatFleetContext.Provider
      value={{ boatFleet, onSearch, getBoatFleetList }}
    >
      {children}
    </BoatFleetContext.Provider>
  );
};

export const useBoatFleetContext = () => {
  return useContext(BoatFleetContext);
};

export const withBoatFleetContext = (Component: any) => {
  return (props: any) => (
    <BoatFleetProvider>
      <Component {...props} />
    </BoatFleetProvider>
  );
};
