import { useState, useEffect } from "react";
import {
  Flex,
  Select,
  TextInput,
  MultiSelect,
  Button,
  PasswordInput,
  Box,
  Text,
  Loader,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import toast from "react-hot-toast";
import { USER_ROLE } from "shared/Constants/general.const";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";
import { useUserManagementContext } from "../../UserManagement.context";

/**
 * Compares two arrays and returns true if they are equal
 *
 * @param {array} a first array to compare
 * @param {array} b second array to compare
 * @returns {boolean} true if the arrays are equal, false otherwise
 */
const arrayEquals = (a, b) => {
  // Check if both are arrays
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }

  // Check if they have the same length
  if (a.length !== b.length) {
    return false;
  }

  // Compare each element
  return a.every((val, index) => val === b[index]);
};

const AddUserDetails = ({ close }) => {
  const [clubsList, setClubsList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [harborsList, setHarborsList] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { userPermissions } = useUserContext();
  const {
    addUserDetails,
    clubsDetails,
    regionsDetails,
    harborsDetails,
    fetchHaborByRegion,
    fetchRegionsByClub,
  } = useUserManagementContext();

  let userRoleData = [];
  if (userPermissions.roleName === USER_ROLE.MOXSEA_Support.value) {
    userRoleData = [
      {
        label: USER_ROLE.Clubmanager.label,
        value: USER_ROLE.Clubmanager.value,
      },
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else if (userPermissions.roleName === USER_ROLE.Clubmanager.value) {
    userRoleData = [
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else if (userPermissions.roleName === USER_ROLE.Clubworker.value) {
    userRoleData = [
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else {
    userRoleData = [
      {
        label: USER_ROLE.MOXSEA_Administrator.label,
        value: USER_ROLE.MOXSEA_Administrator.value,
      },
      {
        label: USER_ROLE.MOXSEA_Support.label,
        value: USER_ROLE.MOXSEA_Support.value,
      },
      {
        label: USER_ROLE.Clubmanager.label,
        value: USER_ROLE.Clubmanager.value,
      },
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  }

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userRole: "",
      password: "",
      allClubs: [],
      club: "",
      allRegions: [],
      allHarbors: [],
    },

    validate: {
      firstName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      email: isEmail("Invalid email"),
      userRole: (value) => (!value ? "Please select User Role" : null),
      password: (value) => (value.length < 1 ? "Please enter password" : null),
      allClubs: (value, values) =>
        !value.length && form.values.userRole === USER_ROLE.MOXSEA_Support.value
          ? "Club is required"
          : null,
      club: (value, values) =>
        !value &&
        (form.values.userRole === USER_ROLE.Clubmanager.value ||
          form.values.userRole === USER_ROLE.Clubworker.value)
          ? "Club is required"
          : null,

      allRegions: (value, values) =>
        form.values.userRole === USER_ROLE.MOXSEA_Administrator.value &&
        USER_ROLE.MOXSEA_Support.value &&
        USER_ROLE.Clubmanager.value
          ? null
          : form.values.userRole === USER_ROLE.Clubworker.value &&
            !value?.length
          ? "Region is required"
          : null,

      allHarbors: (value, values) =>
        form.values.userRole === USER_ROLE.MOXSEA_Administrator.value &&
        USER_ROLE.MOXSEA_Support.value &&
        USER_ROLE.Clubmanager.value
          ? null
          : form.values.userRole === USER_ROLE.Clubworker.value &&
            !value?.length
          ? "Harbor is required"
          : null,
    },
  });

  // Get ClubList
  useEffect(() => {
    let clubNamesAll = [];
    if (clubsDetails?.length > 0) {
      clubNamesAll = clubsDetails?.map((club) => {
        return {
          clubName: club?.clubName || "",
          clubId: club?.id || "",
        };
      });
    }

    setClubsList(clubNamesAll);
  }, [clubsDetails]);

  // Get RegionList
  useEffect(() => {
    let regionsNamesAll = [];
    if (regionsDetails?.length > 0) {
      regionsNamesAll = regionsDetails?.map((region) => {
        return {
          regionName: region?.regionName || "",
          regionId: region?.regionId || "",
        };
      });
    }
    setRegionsList(regionsNamesAll);
  }, [regionsDetails]);

  // Get HarborsList
  useEffect(() => {
    let harborsNamesAll = [];
    if (harborsDetails?.length > 0) {
      harborsNamesAll = harborsDetails?.map((harbor) => {
        return {
          harborName: harbor?.harborName || "",
          harborId: harbor?.id || "",
        };
      });
    }

    setHarborsList(harborsNamesAll);
  }, [harborsDetails]);

  /**
   * Handles the save button click event.
   * Sends a request to the server to add the user details.
   */
  const handleSave = () => {
    const role = form.values.userRole;

    setIsLoading(true);
    const postData = {
      /** First name of the user */
      firstName: form.values.firstName,
      /** Last name of the user */
      lastName: form.values.lastName,
      /** Password of the user */
      password: form.values.password,
      /** Email of the user */
      email: form.values.email,
      /** Role of the user */
      role: form.values.userRole,
      /** List of regions that the user has access to */
      accessedRegions: form.values.allRegions || [],
      /** List of harbors that the user has access to */
      accessedHarbors: form.values.allHarbors || [],
      /** Status of the user */
      status: "active",
    };

    if (role === USER_ROLE.Clubworker.value) {
      /** List of clubs that the user has access to */
      postData.accessedClubs = Array(form.values.club) || [];

      /** List of regions that the user has access to */
      postData.accessedRegions = form.values.allRegions || [];

      /** List of harbors that the user has access to */
      postData.accessedHarbors = form.values.allHarbors || [];
    } else if (role === USER_ROLE.MOXSEA_Administrator.value) {
      /** List of all club IDs */
      postData.accessedClubs = clubsDetails.map((club) => club.id);
    } else if (role === USER_ROLE.MOXSEA_Support.value) {
      /** List of club IDs that the user has access to */
      postData.accessedClubs = form.values.allClubs || [];
    } else if (role === USER_ROLE.Clubmanager.value) {
      /** List of club IDs that the user has access to */
      postData.accessedClubs = Array(form.values.club) || [];
    } else {
      /** Empty lists for all the fields */
      postData.accessedClubs = [];
      postData.accessedRegions = [];
      postData.accessedHarbors = [];
    }

    addUserDetails(postData)
      .then(() => {
        toast.success("New user added successfully!");
        close();
        setIsLoading(false);
      })
      .catch((error) => {
        toast.success(error.message || "Save failed. Please try again.");
        setIsLoading(false);
      });
  };

  /**
   * Handles the "Select all regions" button click event.
   * Sets the allRegions field in the form to the array of all region IDs.
   * If the list of all regions is different than the current value of allRegions
   * in the form, calls the handleAllRegionsChange function to update the list
   * of harbors.
   */
  const handleSelectAllRegions = () => {
    const selectedRegionsIds = regionsList.map((region) => region?.regionId);
    form.setFieldValue("allRegions", selectedRegionsIds);
    if (!arrayEquals(form.values.allRegions, selectedRegionsIds)) {
      handleAllRegionsChange(selectedRegionsIds);
    }
  };

  /**
   * Handles the "Select all harbors" button click event.
   * Sets the allHarbors field in the form to the array of all harbor IDs.
   */
  const handleSelectAllHarbors = () => {
    const allHarborIds = harborsList?.map((harbor) => harbor?.harborId) || [];
    form.setFieldValue("allHarbors", allHarborIds);
  };

  const handleAllRegionsChange = (selectedRegions) => {
    if (selectedRegions && selectedRegions.length > 0) {
      fetchHaborByRegion(selectedRegions);
    }

    form.setFieldValue("allRegions", selectedRegions);
    form.setFieldValue("allHarbors", []);
  };

  const handleClubChange = (selectedClubId) => {
    if (selectedClubId && selectedClubId.length > 0) {
      fetchRegionsByClub(selectedClubId);
    }

    form.setFieldValue("club", selectedClubId);
    form.setFieldValue("allRegions", []);
    form.setFieldValue("allHarbors", []);
  };

  const handleUserRoleChange = (selectedUserRole) => {
    form.setFieldValue("userRole", selectedUserRole);
    form.setFieldValue("club", "");
    form.setFieldValue("allClubs", []);
    form.setFieldValue("allRegions", []);
    form.setFieldValue("allHarbors", []);
  };

  useEffect(() => {
    if (userPermissions.roleName === USER_ROLE.Clubmanager.value) {
      form.setFieldValue("userRole", userRoleData[0].value);
      if (count < 6) {
        form.setFieldValue("club", clubsList[0]?.clubId);
        handleClubChange(clubsList[0]?.clubId);
        handleSelectAllRegions();
        handleSelectAllHarbors();
        setCount(count + 1);
      }
    }
  }, [clubsList, regionsDetails]);

  useEffect(() => {}, [form.values.userRole]);

  return (
    <Flex direction="column" h={"100%"}>
      {isLoading ? (
        <Flex justify={"center"} align={"center"}>
          <Loader />
        </Flex>
      ) : (
        <Box
          component="form"
          onSubmit={form.onSubmit(() => {
            if (form.isValid()) {
              handleSave();
            }
          })}
        >
          <Flex rowGap={12} direction="column">
            <TextInput
              label="Email"
              placeholder="Email"
              withAsterisk
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Password"
              placeholder="Password"
              withAsterisk
              {...form.getInputProps("password")}
            />
            <TextInput
              label="First Name"
              placeholder="First Name"
              withAsterisk
              {...form.getInputProps("firstName")}
            />
            <TextInput
              label="Last Name"
              placeholder="Last Name"
              withAsterisk
              {...form.getInputProps("lastName")}
            />
            <Select
              withAsterisk
              label="User Role"
              placeholder="User Role"
              clearable
              {...form.getInputProps("userRole")}
              data={userRoleData}
              onChange={(value) => {
                handleUserRoleChange(value);
              }}
            />
          </Flex>
          {form.values.userRole === USER_ROLE.MOXSEA_Support.value && (
            <Flex direction="column">
              <Flex align="center" mt="sm">
                <MultiSelect
                  withAsterisk
                  w="110%"
                  label="Accessed Clubs"
                  hidePickedOptions
                  placeholder={
                    form.values.allClubs?.length > 0 ? "" : "Accessed Clubs"
                  }
                  clearable
                  data={clubsList?.map((club) => ({
                    label: club.clubName,
                    value: club.clubId,
                  }))}
                  {...form.getInputProps("allClubs")}
                />
                <Flex mt="md" w="30%" justify="flex-end">
                  <Button
                    variant="subtle"
                    onClick={() => {
                      form.setFieldValue(
                        "allClubs",
                        clubsList?.map((club) => club?.clubId)
                      );
                    }}
                  >
                    <Text td="underline"> Select All</Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
          {form.values.userRole === USER_ROLE.Clubworker.value && (
            <Flex direction="column">
              <Select
                label="Club"
                mt={"sm"}
                placeholder="Club"
                withAsterisk
                clearable
                data={clubsList?.map((club) => ({
                  label: club.clubName,
                  value: club.clubId,
                }))}
                {...form.getInputProps("club")}
                onChange={(value) => handleClubChange(value)}
              />
              {form.values.club && (
                <>
                  <Flex align="center" mt="sm">
                    <MultiSelect
                      withAsterisk
                      label="Accessed Regions"
                      hidePickedOptions
                      w="110%"
                      placeholder={
                        form.values.allRegions?.length > 0
                          ? ""
                          : "Accessed Regions"
                      }
                      clearable
                      disabled={!form.values.club}
                      data={regionsList?.map((region) => ({
                        label: region.regionName,
                        value: region.regionId,
                      }))}
                      {...form.getInputProps("allRegions")}
                      onChange={(value) => handleAllRegionsChange(value)}
                    />
                    <Flex mt="md" align="flex-end" w="30%" justify="flex-end">
                      <Button
                        variant="subtle"
                        onClick={() => {
                          handleSelectAllRegions();
                        }}
                      >
                        <Text td="underline">Select All</Text>
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex align="center" mt="sm">
                    <MultiSelect
                      withAsterisk
                      label="Accessed Harbors"
                      hidePickedOptions
                      w="110%"
                      placeholder={
                        form.values.allHarbors?.length > 0
                          ? ""
                          : "Accessed Harbors"
                      }
                      clearable
                      disabled={!form.values.allRegions.length}
                      data={harborsList?.map((harbor) => ({
                        label: harbor.harborName,
                        value: harbor.harborId,
                      }))}
                      {...form.getInputProps("allHarbors")}
                    />
                    <Flex mt="md" align="flex-end" w="30%" justify="flex-end">
                      <Button
                        variant="subtle"
                        disabled={!form.values.allRegions.length}
                        onClick={() => {
                          handleSelectAllHarbors();
                        }}
                      >
                        <Text td="underline">Select All</Text>
                      </Button>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          )}
          {form.values.userRole === USER_ROLE.Clubmanager.value && (
            <Flex direction="column" mt={"sm"}>
              <Select
                label="Club"
                placeholder="Club"
                withAsterisk
                clearable
                data={clubsList?.map((club) => ({
                  label: club.clubName,
                  value: club.clubId,
                }))}
                {...form.getInputProps("club")}
                onChange={(value) => handleClubChange(value)}
              />
            </Flex>
          )}
          <Flex justify="space-between" mt="md">
            <Button onClick={close}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default AddUserDetails;
