import loginRoutes from "Components/Templates/Login/login.routes";
import { RouterProvider, redirect } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import themeRoutes from "theme/app/routes";
import "./theme/styles/app/app.scss";
import { GlobalFonts } from "App.style";
import ForgotPasswordRoutes from "Components/Templates/ForgotPassword/ForgotPassword.routes";
import { withUserContext } from "./shared/ContextProviders/UserContextProvider/UserContextProvider";
import ToastContainer from "Components/Molecules/ToastContainer/ToastContainer";
import {
  Accordion,
  MantineProvider,
  Modal,
  ScrollArea,
  Image,
  Tooltip,
  createTheme,
  Loader,
} from "@mantine/core";
// core styles are required for all packages
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/carousel/styles.css";
import "mantine-react-table/styles.css";
import "react-international-phone/style.css";
import ResetPasswordRoutes from "Components/Templates/ResetPassword/ResetPassword.routes";
import { COLORS } from "shared/Constants/general.const";

const router = createBrowserRouter([
  ...loginRoutes,
  ...ForgotPasswordRoutes,
  ...ResetPasswordRoutes,
  ...themeRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/login"),
  },
]);

const theme = createTheme({
  defaultRadius: "xl",
  fontFamily: "Public Sans, sans-serif",
  components: {
    Modal: Modal.extend({
      defaultProps: {
        radius: "lg",
        scrollAreaComponent: ScrollArea.Autosize,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayProps: {
          backgroundOpacity: 0.55,
          blur: 3,
        },
      },
      styles: {
        title: {
          // color: COLORS.PRIMARY_BLUE,
          // color: COLORS.G5,
          fontWeight: 500,
        },
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        radius: "sm",
        openDelay: 750,
      },
    }),
    Accordion: Accordion.extend({
      defaultProps: {
        radius: "lg",
      },
    }),
    Image: Image.extend({
      defaultProps: {
        radius: "lg",
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        size: "xl",
      },
    }),
  },
  colors: {
    "moxsea-blue": [
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
      COLORS.PRIMARY_BLUE,
    ],
  },
  primaryColor: "moxsea-blue",
});
function App() {
  return (
    <>
      <MantineProvider theme={theme}>
        <GlobalFonts />
        <RouterProvider router={router} />
        <ToastContainer />
      </MantineProvider>
    </>
  );
}

export default withUserContext(App);
