const STORAGE_KEYS = {
  TOKEN: "TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  USER_DETAILS: "USER_DETAILS",
  // USER_PERMISSIONS: "USER_PERMISSIONS",
  CLUB_ID: "CLUB_ID",
  REGION_ID: "REGION_ID",
  HARBOR_ID: "HARBOR_ID",
  CALENDER_SECTION_HEIGHT: "CALENDER_SECTION_HEIGHT"

};

export const Storage = {
  get: (key: string) => {
    return localStorage.getItem(key);
  },
  set: (key: string, value: any) => {
    return localStorage.setItem(
      key,
      typeof value !== "string" ? JSON.stringify(value) : value
    );
  },
  remove: (key: string) => {
    return localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
  KEYS: STORAGE_KEYS,
};
