import React, { lazy, Suspense } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const ResetPassword = lazy(() => import("./ResetPassword"));

const ResetPasswordRoutes = [
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    element: (
      <Suspense>
        <ResetPassword />
      </Suspense>
    ),
  },
];

export default ResetPasswordRoutes;
