import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const Manuals = lazy(() => import("./Manuals"));

const ManualsRoutes = [
  {
    path: ROUTE_PATHS.MANUALS,
    element: (
      <Suspense>
        <Manuals />
      </Suspense>
    ),
  },
];

export default ManualsRoutes;
