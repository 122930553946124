import * as S from "./BoatFleetTab.style";
import { withBoatFleetContext } from "./BoatFleet.context";
import { Outlet } from "react-router-dom";

const BoatFleetTab = () => {
  return (
    <S.BoatFleetTabContainer className="w-100">
      <Outlet />
    </S.BoatFleetTabContainer>
  );
};

export default withBoatFleetContext(BoatFleetTab);
