import { Suspense, lazy } from "react";

const ClientsViewAll = lazy(() => import("./ClientsViewAll"));

const ClientsViewAllRoutes = [
  {
    path: "",
    element: (
      <Suspense>
        <ClientsViewAll />
      </Suspense>
    ),
  },
];

export default ClientsViewAllRoutes;
