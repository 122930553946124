import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const Bookings = lazy(() => import("./Bookings"));

const BookingsRoutes = [
  {
    path: ROUTE_PATHS.BOOKINGS,
    element: (
      <Suspense>
        <Bookings />
      </Suspense>
    ),
  },
];

export default BookingsRoutes;
