import { useMemo, useState } from "react";

const usePersistedState = <T = any>(initialState: T, key: string) => {
  const getInitialValue = () => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : initialState;
  };
  const initialValue = useMemo(getInitialValue, [initialState, key]);
  const [state, setState] = useState(initialValue);

  const setPersistedState = (newState: T) => {
    localStorage.setItem(key, JSON.stringify(newState));
    setState(newState);
  };

  return [state, setPersistedState];
};

export default usePersistedState;
