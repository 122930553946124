import { Suspense, lazy } from "react";

const AddClients = lazy(() => import("./AddClients"));

const AddClientRoutes = [
  {
    path: "",
    element: (
      <Suspense>
        <AddClients />
      </Suspense>
    ),
  },
];

export default AddClientRoutes;
