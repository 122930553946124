import { LoginAPIResponse } from "shared/model/api.model";
import { Storage } from "../Storage";
import { API_URLS, BASE_URL } from "shared/Constants/api.const";
import http from "./index";
import { tokenRefreshSubject } from "shared/Subjects";

let isRefreshing = false;

const refreshTokenAndFireSubject = async () => {
  isRefreshing = true;
  try {
    const refreshToken = Storage.get(Storage.KEYS.REFRESH_TOKEN);
    const requestData = new FormData();
    requestData.append("refreshToken", `${refreshToken}`);
    requestData.append("userType", "ADMIN_PORTAL_USER");
    const response = await fetch(`${BASE_URL}${API_URLS.REFRESH_TOKEN}`, {
      method: "POST",
      body: requestData,
    });
    if (response.ok) {
      const { token, refreshToken } =
        await (response.json() as Promise<LoginAPIResponse>);
      Storage.set(Storage.KEYS.TOKEN, token);
      Storage.set(Storage.KEYS.REFRESH_TOKEN, refreshToken);
      tokenRefreshSubject.next(true);
    } else {
      tokenRefreshSubject.next(false);
    }
  } catch (error) {
    tokenRefreshSubject.next(false);
  } finally {
    isRefreshing = false;
  }
};

const handleTokenExpiry = async <T>(
  type: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  url: string,
  data?: any
) => {
  if (!isRefreshing) {
    refreshTokenAndFireSubject();
  }
  return new Promise<T>((resolve, reject) => {
    tokenRefreshSubject.subscribe(async (isSuccess) => {
      if (isSuccess) {
        try {
          let response: T;
          switch (type) {
            case "GET":
              response = await http.get<T>(url);
              break;
            case "POST":
              response = await http.post<T>(url, data);
              break;
            case "PUT":
              response = await http.put<T>(url, data);
              break;
            default:
              response = await http.get<T>(url);
              break;
          }
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }
    });
  });
};

export default handleTokenExpiry;
