import { createContext, useContext, useState } from "react";

import { API_URLS } from "shared/Constants/api.const";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";
import http from "shared/utils/http";

const CenterTabContext = createContext({
  harbourDetailsEdit: {},
  setLoading: () => {},
  uploadImage: () => {},
  loading: true,
});

export const CenterTabContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const uploadImage = async (imageFile) => {
    //API for upload image

    const folderName = S3_FOLDER_NAME.HARBORS_IMAGES;
    const formData = new FormData();
    formData.append("files", imageFile);
    let res;

    res = await http.postFormData(
      `${API_URLS.UPLOAD_IMAGE}${folderName}`,
      formData
    );

    return res;
  };

  //PUT API Call
  const harbourDetailsEdit = async (newharbourDetails, harborId) => {
    setLoading(true);

    await http.put(`${API_URLS.EDIT_HARBOUR}/${harborId}`, newharbourDetails);
  };

  return (
    <CenterTabContext.Provider
      value={{
        harbourDetailsEdit,
        uploadImage,
        loading,
        setLoading,
      }}
    >
      {children}
    </CenterTabContext.Provider>
  );
};

export const useCenterTabContext = () => useContext(CenterTabContext);
