import { Suspense, lazy } from "react";

const RegionViewAll = lazy(() => import("./RegionViewAll"));

const RegionViewAllRoutes = [
  {
    path: "",
    element: (
      <Suspense>
        <RegionViewAll />
      </Suspense>
    ),
  },
];

export default RegionViewAllRoutes;
