import React, {
  ComponentType,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { OnUserLoginParams } from "./UserContextProvider.model";
import {
  UserDetailsAPIResponse,
  UserPermissionsAPIResponse,
} from "shared/model/api.model";
import { Storage } from "shared/utils/Storage";
import { toast } from "react-hot-toast";
import usePersistedState from "shared/hooks/usePersistedState";
import { tokenRefreshSubject } from "shared/Subjects";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";

const UserContext = React.createContext<{
  isLoggedIn: boolean | null;
  onLoginUser: (params: OnUserLoginParams) => void;
  userDetails: null;
  onLogoutUser: () => void;
  userPermissions: UserPermissionsAPIResponse | null;
  setCrmFlag: (flag: boolean) => void;
  crmFlag: boolean;
}>({
  isLoggedIn: false,
  onLoginUser: (_: OnUserLoginParams) => {},
  onLogoutUser: () => {},
  userDetails: null,
  userPermissions: null,
  setCrmFlag: (_: boolean) => {},
  crmFlag: false,
});

const UserContextProvider = ({ children }: { children: any }) => {
  const [isLoggedIn, setIsLoggedIn] = usePersistedState<boolean | null>(
    null,
    "isLoggedIn"
  );
  const [crmFlag, setCrmFlag] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] =
    useState<UserPermissionsAPIResponse | null>(null);
  const [userDetails, setUserDetails] =
    usePersistedState<UserDetailsAPIResponse | null>(null, "userDetails");

  const onLoginUser = ({
    token: tokenAPIResponse,
    userDetails: userDetailsAPIResponse,
  }: OnUserLoginParams) => {
    setIsLoggedIn(true);
    Storage.set(Storage.KEYS.TOKEN, tokenAPIResponse);
    setUserDetails(userDetailsAPIResponse);
    toast.success("Login successful!");
  };
  const fetchPermissionList = async (userId: string) => {
    if (userId) {
      return await http
        .get(`${API_URLS.USER_PERMISSIONS}?userId=${userId}`)
        .then((response) => {
          if (response && response.userPermissions) {
            setUserPermissions(response.userPermissions);
          }
        })
        .catch((error) => {
          toast.error(error || "Error fetching permission list");
        });
    }
  };

  useEffect(() => {
    fetchPermissionList(userDetails?.userId || "");
  }, [userDetails]);

  const onLogoutUser = useCallback(() => {
    Storage.clear();
    setIsLoggedIn(false);
    setUserDetails(null);
    setUserPermissions(null);
  }, [setIsLoggedIn, setUserDetails]);

  /**
   * Log the user out if the token refresh fails
   */
  useEffect(() => {
    tokenRefreshSubject.subscribe((isSuccess) => {
      if (!isSuccess) {
        onLogoutUser();
      }
    });
  }, [onLogoutUser]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        onLoginUser,
        onLogoutUser,
        userPermissions,
        userDetails,
        setCrmFlag,
        crmFlag,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

export const useUserContext = () => {
  const userContextState = useContext(UserContext);
  return userContextState;
};

export const withUserContext = (Component: ComponentType) => (props: any) =>
  (
    <UserContextProvider>
      <Component {...props} />
    </UserContextProvider>
  );
