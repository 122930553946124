import { Title, Container } from "@mantine/core";
import { createContext, useContext, useEffect, useState } from "react";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";
import toast from "react-hot-toast";

const MembershipsTabContext = createContext({
  membersData: [],
  boatsData: [],
  handleSave: () => {},
  fetchMembershipDetails: () => {},
  membershipDetailsEdit: {},
  servicesIds: [],
  loading: true,
});

export const MembershipsTabContextProvider = ({ children }) => {
  const [boatsData, setBoatsData] = useState([]);
  const [harborId, setHarborId] = useState("");
  const { selectedClub } = useClubsContext();
  const [membersData, setMembersData] = useState([]);
  const [servicesIds, setServicesIds] = useState([]);
  const [loading, setLoading] = useState(true);

  const Services = async () => {
    const response = await http
      .get(`${API_URLS.GET_SERVICE_CATEGORIES_IDS}`)
      .then((data) => data)
      .catch((error) => {});
    setServicesIds(response.ServiceCategories);
  };

  // GET Api call
  const fetchMembershipList = async (harborId) => {
    //API for Boats base on harbor id
    if (harborId) {
      const responseBoats = await http
        .get(`${API_URLS.GET_BOATS_BY_HARBOUR}?harborIds=${harborId}`)
        .then((data) => data)
        .catch((error) => {});

      setBoatsData(responseBoats?.boatResponses || []);

      //API for memberships Data
      const response = await http
        .get(
          `${API_URLS.GET_MEMBERSHIP_LIST.replace(
            ":clubId",
            selectedClub?.clubId
          ).replace(":harborId", harborId)}`
        )
        .then((data) => data)
        .catch((error) => {});

      if (response?.membershipLevelResponseModels) {
        response?.membershipLevelResponseModels.forEach((membershipLevel) => {
          membershipLevel.cleaning = String(membershipLevel.cleaning);
          membershipLevel.refueling = String(membershipLevel.refueling);
          membershipLevel.waterToys = String(membershipLevel.waterToys);
          membershipLevel.captain = String(membershipLevel.captain);
          membershipLevel.docking = String(membershipLevel.docking);
        });
      }

      setMembersData(response?.membershipLevelResponseModels || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    Services();
    fetchMembershipList(harborId);
  }, [harborId]);

  // POST Api call
  const handleSave = async (data) => {
    setLoading(true);
    await http.post(API_URLS.ADD_MEMBERSHIPS, data).then(() => {
      toast.success("Membership level added successfully!");
      setLoading(false);
      fetchMembershipList(harborId);
    });
  };

  // Create a function for PUT membershipDetailsEdit

  const membershipDetailsEdit = async (
    newmembershipDetails,
    membershipLevelId
  ) => {
    setLoading(true);
    await http
      .put(
        `${API_URLS.EDIT_MEMBERSHIPS}/${membershipLevelId}`,
        newmembershipDetails
      )
      .then(() => {
        setLoading(false);
        toast.success("Membership level updated successfully!");
        fetchMembershipList(harborId);
      })
      .catch((error) => {});
  };
  return (
    <MembershipsTabContext.Provider
      value={{
        membersData,
        boatsData,
        handleSave,
        fetchMembershipList,
        membershipDetailsEdit,
        setHarborId,
        servicesIds,
        loading,
      }}
    >
      {children}
    </MembershipsTabContext.Provider>
  );
};

export const useMembershipsTabContext = () => useContext(MembershipsTabContext);
