import { Storage } from "../Storage";

/**
 * Retrieves the common API headers including authentication token if available.
 *
 * @returns A Promise that resolves to an object representing the headers to be used in API requests.
 */
const getHeaders = () => {
  const token = Storage.get(Storage.KEYS.TOKEN);
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
    origin: "*",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export default getHeaders;
