import React from "react";
import { useToaster } from "react-hot-toast";
import { Alert } from "react-bootstrap";

const ToastVariantMap = {
  success: "success",
  error: "danger",
  loading: "secondary",
  blank: "info",
  custom: "info",
};

const ToastContainer = (props) => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;
  return (
    <div
      style={{
        position: "fixed",
        top: 8,
        right: 8,
        width: "300px",
        zIndex: 1000,
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8,
        });

        const ref = (el) => {
          if (el && typeof toast.height !== "number") {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };
        return (
          <Alert
            key={toast.id}
            ref={ref}
            variant={ToastVariantMap[toast.type]}
            style={{
              position: "absolute",
              minWidth: "300px",
              maxWidth: "50%",
              transition: "all 0.5s ease-out",
              opacity: toast.visible ? 1 : 0,
              transform: `translateY(${offset}px)`,
            }}
            {...toast.ariaProps}
          >
            {toast.message}
          </Alert>
        );
      })}
    </div>
  );
};

export default ToastContainer;
