import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const WhatsNew = lazy(() => import("./WhatsNew"));

const WhatsNewRoutes = [
  {
    path: ROUTE_PATHS.WHATSNEW,
    element: (
      <Suspense>
        <WhatsNew />
      </Suspense>
    ),
  },
];

export default WhatsNewRoutes;
