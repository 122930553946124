import GullLayout from "theme/app/GullLayout/GullLayout";
import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../ContextProviders/UserContextProvider/UserContextProvider";
import { withClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";

const useIsUserAuthenticated = () => {
  const { isLoggedIn } = useUserContext();
  return isLoggedIn;
};

const AuthGuard = () => {
  const { pathname } = useLocation();
  const [previouseRoute, setPreviousRoute] = useState(null);

  let authenticated = useIsUserAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (previouseRoute !== null) setPreviousRoute(pathname);
  }, [pathname, previouseRoute]);

  // useEffect(() => {
  //   navigate(ROUTE_PATHS.DASHBOARD);
  // }, [selectedClub]);

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, [authenticated, navigate]);

  return authenticated ? (
    <GullLayout>
      <Outlet />
      {/* <Outlet key={selectedClub?.clubId} /> */}
    </GullLayout>
  ) : null;
};

export default withClubsContext(AuthGuard);
