import { Suspense, lazy } from "react";

const BoatFleetBoatDetails = lazy(() => import("./BoatFleetBoatDetails"));

const BoatFleetBoatDetailsRoutes = [
  {
    path: "boat/:boatId",
    element: (
      <Suspense>
        <BoatFleetBoatDetails />
      </Suspense>
    ),
  },
  {
    path: "boat",
    element: (
      <Suspense>
        <BoatFleetBoatDetails />
      </Suspense>
    ),
  },
];

export default BoatFleetBoatDetailsRoutes;
