import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";
import CenterTab from "./Components/CenterTab";

import HarboursViewAllRoutes from "./Components/HarboursViewAll/HarboursViewAll.routes";
import RegionViewAllRoutes from "./Components/RegionViewAll/RegionViewAll.routes";
import { Flex, Text, Title } from "@mantine/core";
import BoatFleetTab from "./Components/BoatFleetTab";
import BoatFleetListRoutes from "./Components/BoatFleetTab/Components/BoatFleetList/BoatFleetList.routes";
import BoatFleetBoatDetailsRoutes from "./Components/BoatFleetTab/Components/BoatFleetBoatDetails/BoatFleetBoatDetails.routes";
import ServicesTab from "./Components/ServicesTab";
import MembershipsTab from "./Components/MembershipsTab";

const Center = lazy(() => import("./Center"));

const InitialContent = () => {
  return (
    <Flex justify={"center"} align={"center"} mt={"20%"}>
      <Title order={5} c="gray">
        Please select Harbour first, to view details
      </Title>
    </Flex>
  );
};
const CenterRoutes = [
  {
    path: `${ROUTE_PATHS.CENTER}/regions/:regionId/harbours`,
    children: [...HarboursViewAllRoutes],
  },
  {
    path: `${ROUTE_PATHS.CENTER}/regions`,
    children: [...RegionViewAllRoutes],
  },
  {
    path: ROUTE_PATHS.CENTER,
    element: (
      <Suspense>
        <Center />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: <InitialContent />,
      },
      {
        path: "boat-fleet",
        element: (
          <Suspense>
            <BoatFleetTab />
          </Suspense>
        ),
        children: [...BoatFleetListRoutes, ...BoatFleetBoatDetailsRoutes],
      },
      {
        path: "center",
        element: <CenterTab />,
      },
      {
        path: "services",
        element: <ServicesTab />,
      },
      {
        path: "membership",
        element: <MembershipsTab />,
      },
    ],
  },
];

export default CenterRoutes;
