import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const Dashboard = lazy(() => import("./Dashboard"));

const DashboardRoutes = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: (
      <Suspense>
        <Dashboard />
      </Suspense>
    ),
  },
];

export default DashboardRoutes;
