import Subject from "./utils/Subject";

export const tokenRefreshSubject = new Subject();

export const newBookingCreateSubject = new Subject();

export const ClubSwitchSubject = new Subject();

export const ClubAddSubject = new Subject();

export const RegionAddEditSubject = new Subject();
