
import { API_URLS } from "shared/Constants/api.const";
import http from "../http";
import {
  APIClubRegion,
  APIClubsResponse,
  APIRegionCreateRequest,
  APIRegionCreateResponse,
  APIRegionsResponse,
} from "shared/model/API/api.region.model";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";

export const getClubsByCurrentUser = async (clubId?: string) => {

  const urlPathname = clubId ? `${API_URLS.GET_ALL_CLUBS}?clubId=${clubId}` : API_URLS.GET_ALL_CLUBS;
  const url = new URL(urlPathname);
  const clubsResponse = await http.get<APIClubsResponse>(url.toString());
  const clubs = clubsResponse?.clubs;
  return clubs;
};



export const getRegionsByClub = async (
  clubId: string
): Promise<APIClubRegion[]> => {
  const { regions } = await http
    .get<APIRegionsResponse>(`${API_URLS.GET_REGIONS_BY_CLUB}${clubId}`)
    .then((data) => data);
  return regions;
};


export const createRegion = async (region: APIRegionCreateRequest) => {
  return http.post<APIRegionCreateResponse>(API_URLS.CREATE_REGION, region);
};

export const updateRegion = async (
  region: APIRegionCreateRequest,
  regionId: string
) => {
  return http.put<APIRegionCreateResponse>(
    API_URLS.UPDATE_REGION.replace(":regionId", regionId),
    region
  );
};


export const uploadImage = async (imageFile: any) => {
  const folderName = S3_FOLDER_NAME.CLUB_LOGO;
  const formData = new FormData();
  formData.append("files", imageFile);
  let res;

  res = await http.postFormData(
    `${API_URLS.UPLOAD_IMAGE}${folderName}`,
    formData
  );

  return res;
};

