import { API_URLS } from "shared/Constants/api.const";
import http from "../http";
import {
  APIBoatsResponse,
  BoatByIDResponse,
  BoatById,
  BoatCategoriesResponse,
  BoatListDetailedResponse,
  BoatResponseDetailed,
  CategoryResponseWithName,
  SaveBoatRequest,
} from "shared/model/API/api.boat.model";

export const getBoatsByHarbour = async (
  harbourId: string | number,
  startDate?: Date,
  endDate?: Date
) => {
  const url = new URL(API_URLS.GET_BOATS_BY_HARBOUR);
  url.searchParams.append("harborIds", `${harbourId}`);

  const { boatResponses } = await http.get<APIBoatsResponse>(url.toString());
  return boatResponses;
};

export const getBoatListDetailed = async (): Promise<
  BoatResponseDetailed[]
> => {
  const url = new URL(API_URLS.GET_BOAT_LIST_DETAILED);
  const { boats } = await http.get<BoatListDetailedResponse>(url.toString());
  return boats;
};

export const getBoatDetailsById = async (boatId: string): Promise<BoatById> => {
  const url = new URL(
    API_URLS.GET_BOAT_DETAILS_BY_ID.replace(":boatId", boatId)
  );
  const { boatResponses } = await http.get<BoatByIDResponse>(url.toString());
  return boatResponses?.[0];
};

export const getBoatCategoriesByBoatId = async (
  boatId: string
): Promise<CategoryResponseWithName[]> => {
  const url = new URL(API_URLS.GET_BOAT_CATEGORIES);
  url.searchParams.append("boatId", `${boatId}`);
  const { categoryResponseModel } = await http.get<BoatCategoriesResponse>(
    url.toString()
  );
  return categoryResponseModel.map((category) => ({
    ...category,
    name: category.boatCategoryName,
  }));
};

export const getAllBoatCategories = async (): Promise<
  CategoryResponseWithName[]
> => {
  const url = new URL(API_URLS.GET_BOAT_CATEGORIES);
  const { categoryResponseModel } = await http.get<BoatCategoriesResponse>(
    url.toString()
  );
  return categoryResponseModel.map((category) => ({
    ...category,
    name: category.boatCategoryName,
  }));
};

export const addNewBoat = async (saveBoatRequest: SaveBoatRequest) => {
  const url = new URL(API_URLS.ADD_NEW_BOAT);
  await http.post(url.toString(), saveBoatRequest);
};

export const editBoat = async (saveBoatRequest: SaveBoatRequest) => {
  const url = new URL(
    API_URLS.EDIT_BOAT.replace(":boatId", `${saveBoatRequest.id}`)
  );
  delete saveBoatRequest.id;
  await http.put(url.toString(), saveBoatRequest);
};
