import { createGlobalStyle } from "styled-components";
import PublicSansBlack from "././assets/fonts/PublicSans/PublicSans-Black.ttf";
import PublicSansBlackItalic from "././assets/fonts/PublicSans/PublicSans-BlackItalic.ttf";
import PublicSansBold from "././assets/fonts/PublicSans/PublicSans-Bold.ttf";
import PublicSansBoldItalic from "././assets/fonts/PublicSans/PublicSans-BoldItalic.ttf";
import PublicSansExtraBold from "././assets/fonts/PublicSans/PublicSans-ExtraBold.ttf";
import PublicSansExtraBoldItalic from "././assets/fonts/PublicSans/PublicSans-ExtraBoldItalic.ttf";
import PublicSansExtraLight from "././assets/fonts/PublicSans/PublicSans-ExtraLight.ttf";
import PublicSansExtraLightItalic from "././assets/fonts/PublicSans/PublicSans-ExtraLightItalic.ttf";
import PublicSansItalic from "././assets/fonts/PublicSans/PublicSans-Italic.ttf";
import PublicSansLight from "././assets/fonts/PublicSans/PublicSans-Light.ttf";
import PublicSansLightItalic from "././assets/fonts/PublicSans/PublicSans-LightItalic.ttf";
import PublicSansMedium from "././assets/fonts/PublicSans/PublicSans-Medium.ttf";
import PublicSansMediumItalic from "././assets/fonts/PublicSans/PublicSans-MediumItalic.ttf";
import PublicSansRegular from "././assets/fonts/PublicSans/PublicSans-Regular.ttf";
import PublicSansSemiBold from "././assets/fonts/PublicSans/PublicSans-SemiBold.ttf";
import PublicSansSemiBoldItalic from "././assets/fonts/PublicSans/PublicSans-SemiBoldItalic.ttf";
import PublicSansThin from "././assets/fonts/PublicSans/PublicSans-Thin.ttf";
import PublicSansThinItalic from "././assets/fonts/PublicSans/PublicSans-ThinItalic.ttf";

import RobotoBlack from "././assets/fonts/Roboto/Roboto-Black.ttf";
import RobotoBlackItalic from "././assets/fonts/Roboto/Roboto-BlackItalic.ttf";
import RobotoBold from "././assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "././assets/fonts/Roboto/Roboto-BoldItalic.ttf";
import RobotoItalic from "././assets/fonts/Roboto/Roboto-Italic.ttf";
import RobotoLight from "././assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoLightItalic from "././assets/fonts/Roboto/Roboto-LightItalic.ttf";
import RobotoMedium from "././assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoMediumItalic from "././assets/fonts/Roboto/Roboto-MediumItalic.ttf";
import RobotoRegular from "././assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoThin from "././assets/fonts/Roboto/Roboto-Thin.ttf";
import RobotoThinItalic from "././assets/fonts/Roboto/Roboto-ThinItalic.ttf";

export const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'PublicSans-Black';
        src: url(${PublicSansBlack}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-BlackItalic';
        src: url(${PublicSansBlackItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-Bold';
        src: url(${PublicSansBold}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-BoldItalic';
        src: url(${PublicSansBoldItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-ExtraBold';
        src: url(${PublicSansExtraBold}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-ExtraBoldItalic';
        src: url(${PublicSansExtraBoldItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-ExtraLight';
        src: url(${PublicSansExtraLight}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-ExtraLightItalic';
        src: url(${PublicSansExtraLightItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-Italic';
        src: url(${PublicSansItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-Light';
        src: url(${PublicSansLight}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-LightItalic';
        src: url(${PublicSansLightItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-Medium';
        src: url(${PublicSansMedium}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-MediumItalic';
        src: url(${PublicSansMediumItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-Regular';
        src: url(${PublicSansRegular}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-SemiBold';
        src: url(${PublicSansSemiBold}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-SemiBoldItalic';
        src: url(${PublicSansSemiBoldItalic}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-Thin';
        src: url(${PublicSansThin}) format('truetype');
    }
    @font-face {
        font-family: 'PublicSans-ThinItalic';
        src: url(${PublicSansThinItalic}) format('truetype');
    }

    @font-face {
        font-family: 'Roboto-Black';
        src: url(${RobotoBlack}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-BlackItalic';
        src: url(${RobotoBlackItalic}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-Bold';
        src: url(${RobotoBold}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-BoldItalic';
        src: url(${RobotoBoldItalic}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-Italic';
        src: url(${RobotoItalic}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-Light';
        src: url(${RobotoLight}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-LightItalic';
        src: url(${RobotoLightItalic}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-Medium';
        src: url(${RobotoMedium}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-MediumItalic';
        src: url(${RobotoMediumItalic}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-Regular';
        src: url(${RobotoRegular}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-Thin';
        src: url(${RobotoThin}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto-ThinItalic';
        src: url(${RobotoThinItalic}) format('truetype');
    }
`;
