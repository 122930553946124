import { Suspense, lazy } from "react";

const ClientDetails = lazy(() => import("./ClientDetails"));

const CoMember = lazy(() => import("./CoMember/CoMember"));
const CoMemberList = lazy(() => import("./CoMemberList/CoMemberList"));

const ClientDetailsRoutes = [
  {
    path: ":id",
    element: (
      <Suspense>
        <ClientDetails />
      </Suspense>
    ),
  },
  {
    path: "add-co-member/membershipId/:membershipId?/co-memberId/:coMemberId?/name/:name?",
    element: (
      <Suspense>
        <CoMember />
      </Suspense>
    ),
  },
  {
    path: "co-members/membershipId/:membershipId?/co-memberId/:coMemberId?/name/:name?",
    element: (
      <Suspense>
        <CoMemberList />
      </Suspense>
    ),
  },
];

export default ClientDetailsRoutes;
