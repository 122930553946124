type Subscriber = (data: any) => void;

class Subject {
  private callbacks: (Subscriber | null)[] = [];

  subscribe(callback: Subscriber) {
    const index = this.callbacks.push(callback) - 1;
    const unsubscribe = () => {

      this.callbacks[index] = null;
    };
    return { unsubscribe };
  }

  next(data: any) {
    this.callbacks.forEach((callback) => {
      if (callback) {
        callback(data);
      }
    });
  }
}

export default Subject;
