import layout1Settings from "./Layout1/Layout1Settings";

export const GullLayoutSettings = {
  activeLayout: "layout1",
  dir: "ltr",
  layout1Settings,

  customizer: {
    show: true,
    open: true,
  },
  footer: {
    show: true,
  },
};
