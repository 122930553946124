export const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const appUserId = "13c30678-246e-45d2-9da1-3885ab72ca3b";

export const ADMINISTRATOR = {
  CLUB_MANAGEMENT: "Club Management",
  USER_MANAGEMENT: "User Management",
}

export const COLORS = {
  PRIMARY_BLUE: '#0090ff',
  G1: "#242424",
  G2: "#efefef",
  G3: "#949494",
  G4: "#000000",
  G5: "#222222",
  G6: "#DDDDDD"
}

export const FUEL_TYPE = ["Litre", "Gallon"]

export const BOAT_STATUS = ["Activated", "Deactivated"]

export const ENGINE_TYPE = ["Outboarder / Inboarder", "Electric", "Hybrid"]

export const USER_TYPE = {
  CLIENT_USERS: "CLIENT_USERS",
  ADMIN_USERS: "ADMIN_USERS"
}

export const CATEGORY_ORDER = [
  "Water Toys",
  "Catering",
  "Cleaning",
  "Refueling",
  "Other Services",
  "Captain",
];

export const ADDON_CATEGORIES = [
  {
    label: "Water Toys",
    value: "WATER_TOYS",
  },
  {
    label: "Cleaning",
    value: "CLEANING",
  },
  {
    label: "Catering",
    value: "CATERING",
  },
  {
    label: "Captain",
    value: "CAPTAIN",
  },
  {
    label: "Refueling",
    value: "REFUELING",
  },
  {
    label: "Other Services",
    value: "OTHER_SERVICES",
  },
];


export const ADDON_PRODUCT_TYPES = [
  { label: "Service", value: "Service" },
  // { label: "Membership", value: "Membership" },
  { label: "Fuel", value: "Fuel" },
  { label: "Electricity", value: "Electricity" },
  { label: "ExtraDays", value: "ExtraDays" },
  { label: "EngineHours", value: "EngineHours" },
]


export const BOAT_CONDITIONS = [{ label: "PERFECT", value: "PERFECT" }, { label: "GOOD", value: "GOOD" }, { label: "OK", value: "OK" }, { label: "NO", value: "NO" }];

export const SEVERITY_COLORS = {
  HIGH: "#ff8888",
  MEDIUM: "#ffe066",
  LOW: "#72e07d",
}

export const ALL_ISSUES = {
  mooringLines: "Mooring Lines",
  fenders: "Fenders",
  covers: "Covers",
  lowFreshWater: "Low Fresh Water",
  dirtiness: "Dirtiness",
  lowFuelVoltage: "Low Fuel Voltage",
  propellers: "Propellers",
  somethingBroken: "Something Broken",
  coolerHvacSystem: "Cooler Hvac System",
  other: "Other",
};

export const CATEGORY_FILTERS = {
  WATER_TOYS: "Water Toys",
  CATERING: "Catering",
  CLEANING: "Cleaning",
  OTHER_SERVICES: "Other Services",
  REFUELING: "Refueling",
  CAPTAIN: "Captain",
};

export const CENTER_TABS = {
  CENTER: "Center",
  BOAT_FLEETS: "Boat Fleets",
  SERVICES: "Services",
  MEMBERSHIPS: "Memberships",
}

export const BOOKING_TABS = {
  ISSUE_LIST: "Issue List",
  CHECKIN_CHECKOUT: "Check In / Check Out",
}
export const CLUB_TYPE = ["Boat Club",
  "Charter company",
  "Harbour",
  "Boat Manufacturer",
  "Other"]

export const ALL_SERVICE_NAMES = {
  CATERING: "Catering",
  CAPTAIN: "Captain",
  CLEANING: "Cleaning",
  OTHER_SERVICES: "Other Services",
  EXTRA_ORDER: "Extra Order",
  REFUELING: "Refueling",
  WATER_TOYS: "Water Toys",
};
export const STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  TRUE: "true",
  FALSE: "false",
};

export const MEMBERSHIP_TYPE = {
  GOLD: "Gold",
  SILVER: "Silver",
  PLATINUM: "Platinum",
}


export const S3_FOLDER_NAME = {
  DOCUMENTS: 'doc',
  CO_MEMBERS_DOCUMENTS: 'comembers_docs',
  PROFILE_IMAGES: 'user',
  CHECKIN_CHECKOUT_REPORT_ISSUE: 'issues',
  HARBORS_IMAGES: 'harbors',
  BOAT_IMAGES: 'boats',
  CLUB_LOGO: 'club',
  POSTS: 'posts'
};

export const USER_ROLE = {
  MOXSEA_Administrator: {
    value: "MOXSEA_Administrator",
    label: "MOXSEA Administrator",
  },
  MOXSEA_Support: {
    value: "MOXSEA_Support",
    label: "MOXSEA Support",
  },
  Clubmanager: {
    value: "Club_Manager",
    label: "Club Manager",
  },
  Clubworker: {
    value: "Club_Worker",
    label: "Club Worker",
  },
};


export const CountryList = [
  "Andorra, Principality Of",
  "United Arab Emirates",
  "Afghanistan, Islamic State Of",
  "Antigua And Barbuda",
  "Anguilla",
  "Albania",
  "Armenia",
  "Netherlands Antilles",
  "Angola",
  "Antarctica",
  "Argentina",
  "American Samoa",
  "Austria",
  "Australia",
  "Aruba",
  "Azerbaidjan",
  "Bosnia-Herzegovina",
  "Barbados",
  "Bangladesh",
  "Belgium",
  "Burkina Faso",
  "Bulgaria",
  "Bahrain",
  "Burundi",
  "Benin",
  "Bermuda",
  "Brunei Darussalam",
  "Bolivia",
  "Brazil",
  "Bahamas",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Belarus",
  "Belize",
  "Canada",
  "Cocos (Keeling) Islands",
  "Central African Republic",
  "Congo, The Democratic Republic Of The",
  "Congo",
  "Switzerland",
  "Ivory Coast (Cote D'Ivoire)",
  "Cook Islands",
  "Chile",
  "Cameroon",
  "China",
  "Colombia",
  "Costa Rica",
  "Former Czechoslovakia",
  "Cuba",
  "Cape Verde",
  "Christmas Island",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Djibouti",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Estonia",
  "Egypt",
  "Western Sahara",
  "Eritrea",
  "Spain",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands",
  "Micronesia",
  "Faroe Islands",
  "France",
  "France (European Territory)",
  "Gabon",
  "Great Britain",
  "Grenada",
  "Georgia",
  "French Guyana",
  "Ghana",
  "Gibraltar",
  "Greenland",
  "Gambia",
  "Guinea",
  "Guadeloupe (French)",
  "Equatorial Guinea",
  "Greece",
  "S. Georgia & S. Sandwich Isls.",
  "Guatemala",
  "Guam (USA)",
  "Guinea Bissau",
  "Guyana",
  "Hong Kong",
  "Heard And McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Ireland",
  "Israel",
  "India",
  "British Indian Ocean Territory",
  "Iraq",
  "Iran",
  "Iceland",
  "Italy",
  "Jamaica",
  "Jordan",
  "Japan",
  "Kenya",
  "Kyrgyz Republic (Kyrgyzstan)",
  "Cambodia, Kingdom Of",
  "Kiribati",
  "Comoros",
  "Saint Kitts & Nevis Anguilla",
  "North Korea",
  "South Korea",
  "Kuwait",
  "Cayman Islands",
  "Kazakhstan",
  "Laos",
  "Lebanon",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Liberia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Libya",
  "Morocco",
  "Monaco",
  "Moldavia",
  "Madagascar",
  "Marshall Islands",
  "Macedonia",
  "Mali",
  "Myanmar",
  "Mongolia",
  "Macau",
  "Northern Mariana Islands",
  "Martinique (French)",
  "Mauritania",
  "Montserrat",
  "Malta",
  "Mauritius",
  "Maldives",
  "Malawi",
  "Mexico",
  "Malaysia",
  "Mozambique",
  "Namibia",
  "New Caledonia (French)",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "Neutral Zone",
  "Niue",
  "New Zealand",
  "Oman",
  "Panama",
  "Peru",
  "Polynesia (French)",
  "Papua New Guinea",
  "Philippines",
  "Pakistan",
  "Poland",
  "Saint Pierre And Miquelon",
  "Pitcairn Island",
  "Puerto Rico",
  "Portugal",
  "Palau",
  "Paraguay",
  "Qatar",
  "Reunion (French)",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saudi Arabia",
  "Solomon Islands",
  "Seychelles",
  "Sudan",
  "Sweden",
  "Singapore",
  "Saint Helena",
  "Slovenia",
  "Svalbard And Jan Mayen Islands",
  "Slovak Republic",
  "Sierra Leone",
  "San Marino",
  "Senegal",
  "Somalia",
  "Suriname",
  "Saint Tome (Sao Tome) And Principe",
  "Former USSR",
  "El Salvador",
  "Syria",
  "Swaziland",
  "Turks And Caicos Islands",
  "Chad",
  "French Southern Territories",
  "Togo",
  "Thailand",
  "Tadjikistan",
  "Tokelau",
  "Turkmenistan",
  "Tunisia",
  "Tonga",
  "East Timor",
  "Turkey",
  "Trinidad And Tobago",
  "Tuvalu",
  "Taiwan",
  "Tanzania",
  "Ukraine",
  "Uganda",
  "United Kingdom",
  "USA Minor Outlying Islands",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Holy See (Vatican City State)",
  "Saint Vincent & Grenadines",
  "Venezuela",
  "Virgin Islands (British)",
  "Virgin Islands (USA)",
  "Vietnam",
  "Vanuatu",
  "Wallis And Futuna Islands",
  "Samoa",
  "Yemen",
  "Mayotte",
  "Yugoslavia",
  "South Africa",
  "Zambia",
  "Zaire",
  "Zimbabwe",

]




export const Currencies = [
  { id: 1, name: "USD" },
  { id: 2, name: "EUR" },
  { id: 3, name: "JPY" },
  { id: 4, name: "GBP" },
  { id: 5, name: "AUD" },
  { id: 6, name: "CAD" },
  { id: 7, name: "CHF" },
  { id: 8, name: "CNY" },
  { id: 9, name: "SEK" },
  { id: 10, name: "NZD" },
  { id: 11, name: "SGD" },
  { id: 12, name: "HKD" },
  { id: 13, name: "NOK" },
  { id: 14, name: "KRW" },
  { id: 15, name: "MXN" },
  { id: 16, name: "INR" },
  { id: 17, name: "BRL" },
  { id: 18, name: "ZAR" },
  { id: 19, name: "RUB" },
  { id: 20, name: "SAR" },
];

export const Hours = Array.from({ length: 24 }, (_, index) => (index + 1).toString());


export const Vat = Array.from({ length: 100 }, (_, index) => (index + 1).toString());


export const Timezones_List =
  [
    { id: "Dateline Standard Time", value: "(UTC-12:00) International Date Line West", javaZoneId: "Etc/GMT+12" },
    { id: "UTC-11", value: "(UTC-11:00) Coordinated Universal Time-11", javaZoneId: "Etc/GMT+11" },
    { id: "Hawaiian Standard Time", value: "(UTC-10:00) Hawaii", javaZoneId: "Pacific/Honolulu" },
    { id: "Alaskan Standard Time", value: "(UTC-09:00) Alaska", javaZoneId: "America/Anchorage" },
    { id: "Pacific Standard Time", value: "(UTC-08:00) Pacific Time (US & Canada)", javaZoneId: "America/Los_Angeles" },
    { id: "Mountain Standard Time", value: "(UTC-07:00) Mountain Time (US & Canada)", javaZoneId: "America/Denver" },
    { id: "Central Standard Time", value: "(UTC-06:00) Central Time (US & Canada)", javaZoneId: "America/Chicago" },
    { id: "Eastern Standard Time", value: "(UTC-05:00) Eastern Time (US & Canada)", javaZoneId: "America/New_York" },
    { id: "Atlantic Standard Time", value: "(UTC-04:00) Atlantic Time (Canada)", javaZoneId: "America/Halifax" },
    { id: "Greenland Standard Time", value: "(UTC-03:00) Greenland", javaZoneId: "America/Godthab" },
    { id: "Argentina Standard Time", value: "(UTC-03:00) Argentina", javaZoneId: "America/Argentina/Buenos_Aires" },
    { id: "Azores Standard Time", value: "(UTC-01:00) Azores", javaZoneId: "Atlantic/Azores" },
    { id: "GMT Standard Time", value: "(UTC+00:00) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London", javaZoneId: "Europe/London" },
    { id: "Central European Standard Time", value: "(UTC+01:00) Central European Time", javaZoneId: "Europe/Berlin" },
    { id: "South Africa Standard Time", value: "(UTC+02:00) Harare, Pretoria", javaZoneId: "Africa/Johannesburg" },
    { id: "Russian Standard Time", value: "(UTC+03:00) Moscow, St. Petersburg, Volgograd", javaZoneId: "Europe/Moscow" },
    { id: "Arabian Standard Time", value: "(UTC+04:00) Abu Dhabi, Muscat", javaZoneId: "Asia/Dubai" },
    { id: "Pakistan Standard Time", value: "(UTC+05:00) Islamabad, Karachi", javaZoneId: "Asia/Karachi" },
    { id: "India Standard Time", value: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", javaZoneId: "Asia/Kolkata" },
    { id: "Bangkok Standard Time", value: "(UTC+07:00) Bangkok, Hanoi, Jakarta", javaZoneId: "Asia/Bangkok" },
    { id: "China Standard Time", value: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", javaZoneId: "Asia/Shanghai" },
    { id: "Tokyo Standard Time", value: "(UTC+09:00) Osaka, Sapporo, Tokyo", javaZoneId: "Asia/Tokyo" },
    { id: "AUS Eastern Standard Time", value: "(UTC+10:00) Canberra, Melbourne, Sydney", javaZoneId: "Australia/Sydney" },
    { id: "New Zealand Standard Time", value: "(UTC+12:00) Auckland, Wellington", javaZoneId: "Pacific/Auckland" },
    { id: "Tonga Standard Time", value: "(UTC+13:00) Nuku'alofa", javaZoneId: "Pacific/Tongatapu" }
  ]


export const REACT_TABLE_STYLE_PROP = {
  borderRadius: 12,
}

const GeneralConst = () => {
  return;
};

export default GeneralConst;
