import { Suspense, lazy } from "react";
import ROUTE_PATHS from "shared/Constants/routes.const";

const Reports = lazy(() => import("./Reports"));

const ReportsRoutes = [
  {
    path: ROUTE_PATHS.REPORTS,
    element: (
      <Suspense>
        <Reports />
      </Suspense>
    ),
  },
];

export default ReportsRoutes;
